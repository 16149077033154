import { useEffect, useState } from 'react';
import useWindowSize from '../hooks/useWindowSize';
import axios from 'axios';

function Result() {
	const size = useWindowSize();

	const [stands, setStands] = useState([]);

	const fetchResults = async () => {
		const result = await axios.get('/api/v1/result');
		setStands(result.data);
	};

	useEffect(() => {
		fetchResults();
	}, []);

	return (
		<div className='wrapper_in'>
			<div className='container-fluid'>
				<div className='subheader' id='quote'></div>
				<div className='row'>
					<aside className='col-xl-3 col-lg-4' style={{ display: size.width < 992 ? 'none' : 'block' }}>
						<h2>Wer ist Dein Glühweinkönig?</h2>
						<p className='lead'>Bewerte hier den besten Glühwein auf dem Weihnachtsmarkt Stralsund.</p>
						<ul className='list_ok'>
							<li>Wähle deinen Lieblingsstand.</li>
							<li>Sag uns, was du getrunken hast.</li>
							<li>Bewerte deinen Gesamteindruck.</li>
						</ul>
					</aside>
					<div className='col-xl-9 col-lg-8'>
						<div id='wizard_container' className='wizard'>
							<div id='middle-wizard' className='wizard-branch wizard-wrapper'>
								{stands.map((item, idx) => (
									<div key={idx} className='step wizard-step'>
										<h3 className='main_question wizard-header'>
											<strong>{idx + 1}</strong> {item.stand}
										</h3>
										<div className='top-wizard'>
											<strong>Wie gut hat Dir das Getränk geschmeckt?</strong>
											<div
												id='progressbar'
												className='ui-progressbar ui-widget ui-widget-content ui-corner-all'
												role='progressbar'
												aria-valuemin='0'
												aria-valuemax='100'
												aria-valuenow='0'
											>
												<div
													className='ui-progressbar-value ui-widget-header ui-corner-left'
													style={{ width: item.pTaste + '%' }}
												>
													{item.taste.toFixed(2)}/5 ({item.count} Votes)
												</div>
											</div>
										</div>
										<div className='top-wizard'>
											<strong>Wie schätzt Du das Preis-Leistungsverhältnis ein?</strong>
											<div
												id='progressbar'
												className='ui-progressbar ui-widget ui-widget-content ui-corner-all'
												role='progressbar'
												aria-valuemin='0'
												aria-valuemax='100'
												aria-valuenow='0'
											>
												<div
													className='ui-progressbar-value ui-widget-header ui-corner-left'
													style={{ width: item.pCost + '%' }}
												>
													{item.cost.toFixed(2)}/5 ({item.count} Votes)
												</div>
											</div>
										</div>
										<div className='top-wizard'>
											<strong>Wie hat Dir das Ambiente gefallen?</strong>
											<div
												id='progressbar'
												className='ui-progressbar ui-widget ui-widget-content ui-corner-all'
												role='progressbar'
												aria-valuemin='0'
												aria-valuemax='100'
												aria-valuenow='0'
											>
												<div
													className='ui-progressbar-value ui-widget-header ui-corner-left'
													style={{ width: item.pAmbient + '%' }}
												>
													{item.ambient.toFixed(2)}/5 ({item.count} Votes)
												</div>
											</div>
										</div>
										<div className='top-wizard'>
											<strong>Wie organisiert und freundlich war die Bedienung?</strong>
											<div
												id='progressbar'
												className='ui-progressbar ui-widget ui-widget-content ui-corner-all'
												role='progressbar'
												aria-valuemin='0'
												aria-valuemax='100'
												aria-valuenow='0'
											>
												<div
													className='ui-progressbar-value ui-widget-header ui-corner-left'
													style={{ width: item.pService + '%' }}
												>
													{item.service.toFixed(2)}/5 ({item.count} Votes)
												</div>
											</div>
										</div>
										<div className='top-wizard'>
											<strong>
												Wie wahrscheinlich ist Deine Weiterempfehlung des Glühweinstandes?
											</strong>
											<div
												id='progressbar'
												className='ui-progressbar ui-widget ui-widget-content ui-corner-all'
												role='progressbar'
												aria-valuemin='0'
												aria-valuemax='100'
												aria-valuenow='0'
											>
												<div
													className='ui-progressbar-value ui-widget-header ui-corner-left'
													style={{ width: item.pRecommendation + '%' }}
												>
													{item.recommendation.toFixed(2)}/5 ({item.count} Votes)
												</div>
											</div>
										</div>
										<div className='top-wizard'>
											<strong>Gesamt</strong>
											<div
												id='progressbar'
												className='ui-progressbar ui-widget ui-widget-content ui-corner-all'
												role='progressbar'
												aria-valuemin='0'
												aria-valuemax='100'
												aria-valuenow='0'
											>
												<div
													className='ui-progressbar-value ui-widget-header ui-corner-left'
													style={{ width: item.pAverage + '%' }}
												>
													{item.average.toFixed(2)}/5 ({item.count} Votes)
												</div>
											</div>
										</div>
									</div>
								))}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Result;
