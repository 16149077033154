import './assets/scss/fonts.scss';
import './assets/scss/eicons.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/scss/style.scss';
import './App.scss';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Voting from './components/Voting';
import Result from './components/Result';

function App() {
	return (
		<Router>
			<Routes>
				<Route path='/' element={<Voting />}></Route>
                <Route path='/result' element={<Result />}></Route>
			</Routes>
		</Router>
	);
}

export default App;
