import React from 'react';

const MultipleChoice = ({ title, step, values, select, name, state, error }) => {
	return (
		<div className='step wizard-step'>
			<h3 className='main_question wizard-header'>
				<strong>{step}</strong> {title}
			</h3>
			{values.map((item, idx) => (
				<div key={idx} className='form-group radio_questions' onClick={(e) => select(name, idx)}>
					<label className={state[name] === idx ? 'hover' : ''}>
						<strong>{idx + 1}.</strong> {item}
						<div className={state[name] === idx ? 'iradio_square-yellow checked' : 'iradio_square-yellow'}>
							<input
								type='radio'
								value={item}
								checked={state[name] === idx}
								className='icheck required'
								onChange={(e) => {}}
							/>
							{error === true && idx === 0 && (
								<span htmlFor='question_1' className='error'>
									Erforderlich
								</span>
							)}
							<ins className='iCheck-helper'></ins>
						</div>
					</label>
				</div>
			))}
		</div>
	);
};

export default MultipleChoice;
